// date-helper.ts
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

export class Helper {
  static formatDate(isoString: any): string {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  static excelformatDate(dateTime: string | Date): string {
    const date = new Date(dateTime); // Convert to Date object
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return date.toLocaleDateString('en-GB', options);
    // +
    // ' ' +
    // date.toLocaleTimeString('en-US', options)
  }

  static PatternValidator(pattern: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      //   const pattern: RegExp = /^[a-zA-Z0-9\-_&]*$/; // Alphanumeric and (-, _, &) characters allowed
      if (!pattern.test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }

  static numberWithDecimalsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pattern = /^\d+(\.\d{1,2})?$/; // Allow numbers with up to 2 decimal places
      if (control.value && !pattern.test(control.value)) {
        return { numberWithDecimals: true };
      }
      return null;
    };
  }
}
