import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rp-breadcrumb',
  templateUrl: './rp-breadcrumb.component.html',
  styleUrl: './rp-breadcrumb.component.scss',
})
export class RpBreadcrumbComponent {
  @Input() breadcrumbItems: any;
  @Input() title: string | undefined;
  constructor(private router: Router) {}

  ngOnInit() {}
}
