<!-- breadcrumb item -->
<div class="row">
    <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/masters'"><i
                                class="fa fa-home" aria-hidden="true"></i></a>
                    </li>
                    @for(item of breadcrumbItems;track $index){
                    <ng-container>
                        @if(item.active){
                        <li class="breadcrumb-item active"><a [routerLink]="['/' + item.url]">{{ item.label }}</a>
                        </li>
                        }@else{
                        <li class="breadcrumb-item"><a href="javascript: void(0);">{{ item.label }}</a>
                        </li>}
                    </ng-container>
                    }
                </ol>
            </div>
        </div>
    </div>
</div>