import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RpBreadcrumbComponent } from './rp-breadcrumb/rp-breadcrumb.component';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [RpBreadcrumbComponent,],
    imports: [
        CommonModule,
        FormsModule, RouterModule,

    ],
    exports: [RpBreadcrumbComponent,]
})
export class ComponentsModule { }