import { SweetAlertIcon } from '../constants/enum';

export interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  secondaryPhoneNumber: string;
  phoneNumber: number;
  fileUrl: string;
}

export interface ConfirmationModal {
  title?: string;
  text?: string;
  icon?: SweetAlertIcon; // Use the SweetAlertIcon enum here
  showCancelButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export interface ChangePwdModal {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface Faq {
  title: string; // FAQ Title
  content?: string; // FAQ Content
  fileUrl?: string; // File Attachment
  id?: number;
}

export interface Umd {
  id?: string;
  projectId?: string;
  utility: utility;
  macAddress: string;
  meterStatus: string;
}

export interface utility {
  id?: string;
  namae?: string;
}

export interface KeyMapping {
  keyDisplayName: string;
  jsonkeyName: string;
}

export interface ResponseMethodUrlMapping {
  methodUrlMappingId: string;
}

export interface PayloadMethodUrlMapping {
  displayName: string;
  payloadConfigurationId: string;
  methodUrlMappingId: string;
}

export interface ResponseConfiguration {
  responseName: string;
  projectId: string;
  supplierId: string;
  payload: string;
  gatewayAddress: string;
  deviceAddress: string;
  keyMappings: KeyMapping[];
  responseMethodUrlMapping: ResponseMethodUrlMapping;
  payloadMethodUrlMappings: PayloadMethodUrlMapping[];
}
export const table_pageSize = {
  pageSizes: [5, 10, 20, 50, 100, 200, 500, 750, 1000],
};
